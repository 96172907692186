import request from "@/utils/request";

// 获取文章列表
export function fetchList(data) {
	return request({
		url: "/article",
		method: "post",
		data,
	});
}

// 新增文章日志
export function addArticleLog(data) {
	return request({
		url: "/article/log/add",
		method: "put",
		data,
	});
}

// 获取文章详情
export function fetchArticle(id) {
	return request({
		url: `/article/detail/${id}`,
		method: "get",
	});
}

// 文章热度+1
export function addViewsCount(articleId) {
	return request({
		url: `/article/view/add/${articleId}`,
		method: "put",
	});
}

// 文章下载量+1
export function addDownloadsCount(articleId) {
	return request({
		url: `/article/download/add/${articleId}`,
		method: "put",
	});
}

/**
 * 获取文章分类（不分页）
 * @method get
 */
export function fetchCategories(data) {
	return request({
		url: "/blog/category",
		method: "get",
		data,
	});
}
