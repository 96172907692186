<template>
	<div
		id="layout-header"
		:class="{ fixed, hidden }"
		@click.stop="mobileShow = false"
	>
		<div class="site-logo">
			<router-link to="/">
				<img
					src="@/assets/rabbit.svg"
					alt="logo"
					style="width: 40px; height: 40px; margin: 16px 0"
				/>
				<p class="site-name">{{ $t("index.title") }}</p>
			</router-link>
		</div>
		<div class="menus-btn" @click.stop="mobileShow = !mobileShow">Menus</div>
		<div
			class="site-menus"
			:class="{ mobileShow: mobileShow }"
			@click.stop="mobileShow = !mobileShow"
		>
			<div class="weather menu-item">
				<weather-card></weather-card>
			</div>
			<div class="menu-item header-search">
				<header-search />
			</div>
			<div class="menu-item">
				<router-link to="/">{{ $t("index.menu.home") }}</router-link>
			</div>
			<div class="menu-item hasChild">
				<a href="#">{{ $t("index.menu.blog") }}</a>
				<div class="childMenu" v-if="categories.length > 0">
					<div class="sub-menu" v-for="item in categories" :key="item.id">
						<router-link :to="calculateUrl(item)">{{
							calculateTitle(item)
						}}</router-link>
					</div>
				</div>
			</div>
			<div class="menu-item">
				<router-link to="/friend">{{ $t("index.menu.friend") }}</router-link>
			</div>
			<div class="menu-item">
				<router-link to="/resource">{{
					$t("index.menu.resource")
				}}</router-link>
			</div>
			<div class="menu-item">
				<router-link to="/about">{{ $t("index.menu.about") }}</router-link>
			</div>
			<div class="menu-item hasChild">
				<a href="#"
					><img
						src="@/assets/icons/language.png"
						alt=""
						width="20"
						height="20"
						style="vertical-align: middle"
				/></a>
				<div class="childMenu">
					<div class="sub-menu" v-for="item in langTypes" :key="item.type">
						<a @click="toggleLang(item.type)">{{ item.title }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderSearch from "@/components/header-search";
import WeatherCard from "@/components/weather-card";
import { fetchCategories } from "@/api/blog";

export default {
	name: "layout-header",
	components: { HeaderSearch, WeatherCard },
	data() {
		return {
			lastScrollTop: 0,
			fixed: false,
			hidden: false,
			mobileShow: false,
			langTypes: [],
			categories: [],
		};
	},
	computed: {
		calculateUrl() {
			return (item) => {
				return `/category/${item.title_en}`;
			};
		},
		calculateTitle() {
			return (item) => {
				const lang = localStorage.getItem("lang");
				if (lang === "en_US") {
					return item.title_en;
				} else {
					return item.title;
				}
			};
		},
		computeTitle() {
			return (item) => {
				const lang = localStorage.getItem("lang");
				if (lang === "en_US") {
					return item.value;
				} else {
					return item.name;
				}
			};
		},
	},
	created() {
		this.getCategories();
	},
	mounted() {
		window.addEventListener("message", function (msg) {
			if (msg.data.type == "start") {
				console.log("*****4", msg.data.message);
				//执行方法
				setTimeout(() => {
					sessionStorage.setItem("searchCode", msg.data.message);
					window.newWin.close();
					window.location.reload();
				}, 1000);
			}
		});
		window.addEventListener("scroll", this.watchScroll);
		// 获取语言类型
		for (const key in this.$t("index.menu.languages")) {
			this.langTypes.push({
				type: key,
				title: this.$t("index.menu.languages")[key],
			});
		}
		const cui = sessionStorage.getItem("sqlUserInfo");
		this.sqlUserInfo = cui ? JSON.parse(cui) : {};
	},
	watch: {
		loginCount: {
			deep: true,
			handler(newVal) {
				if (newVal > 30) {
					clearInterval(this.loginInterval);
					window.newWin.close();
				}
			},
		},
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.watchScroll);
		clearInterval(this.loginInterval);
	},
	methods: {
		watchScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop === 0) {
				this.fixed = false;
			} else if (scrollTop >= this.lastScrollTop) {
				this.fixed = false;
				this.hidden = true;
			} else {
				this.fixed = true;
				this.hidden = false;
			}
			this.lastScrollTop = scrollTop;
		},
		toggleLang(type) {
			if (type === "en") {
				localStorage.setItem("lang", "en_US");
			} else if (type === "zh") {
				localStorage.setItem("lang", "zh_CN");
			} else {
				localStorage.setItem("lang", "zh_CN");
			}
			window.location.reload();
		},
		// 获取所有分类
		async getCategories() {
			try {
				const res = await fetchCategories();
				if (res.code === 200) {
					this.categories = res.data;
					this.$store.dispatch("setBlogMenuCategories", this.categories);
				} else {
					this.$message.error(res.errors);
				}
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
};
</script>

<style scoped lang="less">
@import "../../assets/css/header.less";
</style>
